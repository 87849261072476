const arLang = {
    addes_successfuly: 'تمت الاضافة بنجاح',
    chose_from_list: 'اختر',
    refresh: 'تحديث',
    vacations: 'الاجازات',
    your_vacation_limit_is_overdue: 'عدد الايام المدخل اكثر من ايام الاجازة المسموحه لك',
    vacation_reasons_required: 'سبب الاجازة مطلوب',
    vacation_start_date_required: 'تاريخ بدء الاجازة مطلوب',
    vacation_remains: 'المتبقي من الاجازة',
    vacation_reasons: 'سبب الاجازة',
    calnder_all_field_required: 'جميع الحقول الخاصة بحجز الموعد مطلوبة',
    customer_support: 'الدعم الفني',
    all_total: "اجمالي المطلوب",
    sales_total: 'اجمالي المبيعات',
    sales_ftotal: 'اجمالي المبيعات مع الضريبة',
    afforded_customer_name: 'عميل التأمين',
    customer_afforded: 'تحمل العميل',
    view_subscriber: 'عرض الاشتراك',
    view_offer: 'كرت العرض',
    all_branches: 'كل الفروع',
    created_date: 'تاريخ الاضافة',
    campaign_profit: 'الدخل',
    campaign_clients: 'عدد العملاء',
    campaign_cost: 'تكلفة البرنامج',
    campaign_target: 'العدد المستهدف',
    campaign_branches: 'الفروع المستهدفة',
    campaign_end: 'تاريخ نهاية العرض',
    vehicle_type: 'نوع المركبة',
    campaign_area: 'المنطقة المستهدفة',
    all_regions: 'كل المدن',
    exact_city: 'مدينة محددة',
    FixedAssets: 'الاصول الثابتة',
    return_quotation: 'تفعيل العرض',
    campaign_price: 'سعر العرض',
    add_title: 'اضافة بنود العقد',
    title_cond: 'البند',
    updatePeriodMaint: 'تعدل الصيانة الدورية',
    period_maint: 'صيانة دورية',
    maint_prog: 'برامج الصيانة',
    maint_contract: 'عقود صيانة',
    number_of_days: 'عدد الايام',
    add_vacation: 'اضافة اجازة',
    po_number: 'رقم المطالبة',
    visit_type: 'نوع الزيارة',
    last_visit: 'آخر زيارة',
    add_periodic_maint: "أضافة صيانة",
    select_store: 'اختر المستودع',
    inventory_report: 'تقرير الجرد',
    coa_report: 'تقرير الدليل',
    coa_report_with_totals: 'تقرير الدليل بالمجاميع',
    parent_class_name: 'اسم الحساب الرئيسي',
    parent_classid: 'الحساب الرئيسي',
    deleted_successfuly: 'تم الحذف بنجاح',
    this_item_used_you_can_not_delete_it: 'العنصر المراد حذفه مستخدم، ولا يمكن حذفه',
    pnl_report: 'تقرير قائمة الدخل',
    hide_zero_accounts: 'اخفاء المجاميع الصفرية',
    trial_balance : 'ميزان المراجعة',
    trial_in_balances: 'الميزان بالارصدة',
    trial_in_totals: 'الميزان بالمجاميع',
    attend_late: 'تاخير حضور',
    left_early: 'انصراف مبكر',
    not_attend: 'غياب',
    select_year: 'اختر السنة',
    select_month: 'اختر الشهر',
    update_add_employee_success: "تمت اضافة / تعديل الموظف بنجاح",
    employee_name_required: 'اسم الموظف مطلوب',
    mobile_number_required: 'رقم الجوال مطلوب',
    base_salary_required: 'الراتب الاساسي مطلوب',
    finger_print_id: 'رقم تعريف البصمة',
    unit_name: 'اسم الوحدة',
    sale_price: 'سعر البيع',
    minimum_salse_price: 'الحد الادنى للبيع',
    maximum_salse_price: 'الحد الاعلى للبيع',
    salesable: 'قابل للبيع',
    none_salesable: 'غير قابل للبيع',
    final_product: 'منتج نهائي',
    raw_material: 'مواد خام',
    factory_item: 'منتج مصنع',
    return_selected: 'ارجاع المحدد',
    delete_selected: 'حذف المحدد',
    delete_invoice: 'حذف الفاتورة',
    edit_voucher: 'تعديل السند',
    add_out_voucher: 'اضافة سند صرف',
    please_add_qtty: 'يرجى اضافة الكمية',
    please_add_purchase_price: 'يرجى اضافة سعر الشراء',
    please_add_purchase_date: 'يرجى اضافة تاريخ الشراء',
    plase_add_assets_ar_name: 'يرجى ادخال اسم الاصل بالعربي',
    plase_add_assets_en_name: 'يرجى ادخال اسم الاصل بالانجليزي',
    fixed_assets_namear: 'اسم الاصل بالعربي',
    fixed_assets_nameen: 'اسم الاصل بالانجليزي',
    add_fixed_assets: 'إضافة اصل ثابت',
    udpate_fixed_assets: 'تعديل اصل ثابت',
    purchase_date: 'تاريخ الشراء',
    purchase_price: 'سعر الشراء',
    depreciation_rate: 'نسبة الاهلاك',
    depreciation_period: 'مدة الاهلاك',
    depreciation_amount: 'قيمة الاهلاك',
    current_price: 'السعر الحالي',
    error_date: 'تاريخ القيد خارج نطاق السنة المالية الحالية يرجى اختيار التاريخ المناسب',
    update_journal:'تعديل القيد',
    add_new_journal: 'اضافة قيد',
    gov_expenses: 'مصروفات حكومية',
    debit: 'مدين',
    analyses_account:'حساب تحليلي',
    main_account: 'حساب فرعي',
    balance_type: 'ميزانية',
    bnl_type: 'ارباح وخسائر',
    income_type: 'إيرادات',
    other_type: 'أخرى',
    add_sub_account: 'اضافة حساب فرعي',
    sub_accounts: 'الحسابات الفرعية',
    note_text_required: 'يرجى اضافة نص الملاحظة',
    card_paid_status: 'حالة السداد',
    card_paid: "مسدد",
    card_not_paid: "غير مسدد",
    is_saleable: 'هل المنتج قابل للبيع',
    product_namear: "اسم المنتج بالعربي",
    product_nameen: "اسم المنتج بالانجليزي",
    no_account_ar: 'بدون حساب',
    unit_namear: 'اسم الوحده بالعربي',
    unit_nameen: 'اسم الوحده بالانجليزي',
    profit: 'الارباح',
    wins: 'صافي الربح',
    costs: 'التكاليف',
    available_qtty: 'الكمية المتاحة',
    transfer_qtty: 'الكمية المحولة',
    from_store: 'التحويل من',
    to_store: 'التحويل إلى',
    visit_oursite: 'زيارة مقرنا',
    vin: 'رقم الشاص',
    color: 'اللون',
    brand: 'الماركة',
    technical:'الفني',
    carstotal: 'عدد السيارات',
    item_totals: 'عدد الاصناف',
    created_by: 'انشأت بواسطة',
    card_supervisor: 'الفني',
    company_name_and_company_address_required: 'اسم الشركة والعنوان دائما مطلوبين',
    please_add_one_item_before_continue: 'يرجى اضافة صنف واحد على الاقل',
    select_store_before_continue: 'يرجى اختيار المستودع',
    temporary_expenses: 'مصروفات مستردة',
    years: 'السنوات',
    renew: 'تجديد',
    renewal_amount: 'التجديد السنوي',
    successfuly_job: 'تمت العملية بنجاح',
    branches: 'الفروع',
    stores: 'المستودعات',
    taxes: 'الضرائب',
    call: 'اتصال',
    custoday_not_equal: "عهدة الموظف لا تغطي تكاليف الفاتورة",
    payment_method_error: "يجب اختيار طريقة دفع قبل المتابعة أو عهدة من موظف",
    empty_invoice_error: 'لا يمكن اضافة فاتورة فارغة',
    date_required: 'يرجى اختيار التاريخ',
    date_out_of_day: "تاريخ الفاتورة يجب ان لا يتجاوز التاريخ الحالي تم اعادة التاريخ إلى تاريخ اليوم",
    all_field_require: 'يرجى اكمال كافة الحقول المطلوبة',
    pay_more_than_price: "لا يمكن سداد مبلغ يتجاوز قيمة اجمالي الفاتورة",
    car_digit_4: "يجب أن تكون ارقام اللوحة مؤلفة من اربع ارقام",
    all_calr_plate_field_required: "يجب تعبئة حقول حروف اللوحة بالكامل",
    mobile_alert_10: "رقم الجوال يجب ان يكون من عشرة ارقام يبدأ برقم 05",
    error_no_qty: "الصنف المختار غير متوفر حاليا... يرجى اضافة فاتورة مشتريات قبل اضافة المخزون ليتم احتساب ضريبة القيمة المضافة بشكل صحيح",
    alert: 'تنبيه',
    notes_require: 'وصف التذكرة مطلوب دائما',
    times_require: 'يرجى اختيار وقت لتنفيذ التذكرة',
    deadline_require: 'تاريخ الانتهاء مطلوب دائما',
    executed_date_require: 'تاريخ التنفيذ مطلوب دائما',
    start_date_require: 'تاريخ البدء مطلوب دائما',
    subject_require: 'عنوات التذكرة مطلوب دائما',
    customer_require: 'يرجى اختيار العميل',
    department:'القسم',
    support_type: 'نوع الدعم',
    available_times: 'الاوقات المتاحة',
    service_name: 'نوع الدعم',
    source: 'المصدر',
    support_case: 'نوع الدعم',
    support_procedure: 'الاجراء المطلوب',
    executed_date: 'تاريخ التنفيذ',
    last_10_messages: 'آخر عشر رسائل',
    product: 'المنتج',
    working_time_star: 'بدء العمل',
    working_time_end: 'انتهاء العمل',
    send: 'إرسال',
    what_to_send: 'نوع الارسال',
    campaign_start: 'تاريخ بداية العرض',
    campaign_start_time: 'وقت البدء',
    campaign_title: 'اسم البرنامج',
    add_new: 'اضافة جديد',
    offer_name: '',
    offer_start: '',
    offer_end: '',
    add_offer: 'اضافة عرض',
    client_source: 'مصدر العميل',
    PublishMe: 'اصدار ترخيص',
    send_attachment: 'السماح بارسال ملفات',
    expire_date: 'تاريخ الانتهاء',
    api_token: 'كود تفعيل الواتس اب (للحصول على الكود الاتصال بـ 0595907777)',
    purchase_inv: 'فاتورة المشتريات',
    web_url: 'رابط النسخة',
    client_priority: 'الأهمية',
    erocedure_date: 'تاريخ الاجراء',
    procedure: 'الاجراء',
    update_procedures: 'تعديل الاجراء',
    saleable: 'قابل للبيع',
    is_finalProduct: 'نوع الصنف',
    vendor_code: 'رقم تعريف المورد',
    unit_type: 'نوع الوحدة',
    main_unit: 'الوحدة الاساسية',
    update_units: 'تعديل وحدة',
    add_unit: 'إضافة وحدة',
    main_unit_ar: 'الوحدة الرئيسية عربي',
    main_unit_en: 'الوحدة الرئيسية انجليزي',
    unitId: 'المعرف',
    old_invoice: 'الفاتورة السابقة',
    title: 'العنوان',
    expected_income: 'الدخل المتوقع',
    job_cards: 'اوامر التشغيل',
    contracts: 'العقود',
    supports: 'الدعم',
    meter_car: 'قراءة العداد',
    contract_date: 'تاريخ العقد',
    contract_expire_date: 'تاريخ الانتهاء',
    contract_start_date: 'تاريخ البدء',
    cars_number: 'عدد السيارات',
    last_maint_by: 'اخر صيانة بواسطة',
    last_maint_date: 'تاريخ اخر صيانة',
    contract_id: 'رقم العقد',
    add_contract: 'إضافة عقد',
    start_qtty: 'الرصيد الافتتاحي',
    cost_price: 'سعر التكلفة',
    add_sinventory: 'إضافة مخزون',
    add_transfer: 'إضافة تحويل',
    trans_from: 'تحويل من',
    trans_to: 'تحويل إلى',
    trans_type: 'نوع التحويل',
    received_by: 'استلام بواسطة',
    account_number: 'رقم الحساب',
    store_name_ar: 'اسم المستودع عربي',
    store_name_en: 'اسم المستودع انجليزي',
    add_store: 'اضافة مستودع',
    card_type: 'نوع الصيانة',
    new_sales: 'كرت جديد',
    return_sales: 'كرت رجيع',
    mobile_required: 'رقم الاتصال مطلوب دائما',
    super_visor_required: 'يجب اختيار مشرف',
    superVisor: 'مشرف مبيعات',
    checklist: 'قائمة التحقق',
    task_title: 'عنوان المهمة',
    task: 'المهمة',
    employee: 'الموظف',
    project_employees: 'موظفي المشروع',
    project_close_date: 'اغلاق المشروع',
    project_start: 'بداية المشروع',
    project_deadline: 'نهاية المشروع',
    project_owner: 'مسؤول المشروع',
    project_type: 'نوع المشروع',
    project_title: 'عنوان المشروع',
    support: 'الدعم الفني',
    industry: 'نوع العمل',
    day_name: 'اليوم',
    exeute_date: 'تاريخ التنفيذ',
    task_priority: 'الأهمية',
    companies: 'فواتير شركات',
    owner_name: 'مالك السيارة',
    add_work_order: 'اضافة امر عمل',
    work_order: 'أوامر العمل',
    employee_name: 'اسم الموظف',
    number_of_tasks: 'عدد المهام',
    closed_tasks: 'مغلق',
    canceled_tasks: 'ملغي',
    opened_tasks: 'تحت الاجراء',
    advanced_payment_deleted: 'تم حذف السلفة بنجاح',
    employee_deleted: 'تم حذف الموظف بنجاح',
    cannot_delete_this_vendor_invoices_found: 'المورد لديه فواتير مشتريات - مصروفات لا يمكن حذفه',
    cannot_delete_this_vendor_payment_found: 'هناك دفعات مسددة للمورد لا يمكن حذف المورد',
    vendor_delete_done: 'تم حذف المورد بنجاح',
    cannot_delete_this_customer_invoices_found: 'لا يمكن حذف عميل لديه فواتير',
    cannot_delete_this_customer_cards_found: 'لا يمكن حذف عميل لديه أوامر تشغيل',
    custoemr_delete_done: 'تم حذف العميل بنجاح',
    client_type: 'نوع العميل',
    message_title: 'عنوان الرسالة',
    image_url: 'رابط الصورة',
    document_url: 'رابط المستند',
    vedio_url: 'رابط الفيديو',
    number_list: 'قائمة الارقام',
    number_list_notes: 'ضع فاصلة بين الارقام',
    sent_type: 'نوع الارسال',
    send_to: 'ارسال إلى',
    send_whatsapp: 'ارسال واتس',
    send_date: 'تاريخ الارسال',
    message_type: 'نوع الرسالة',
    message_text: 'نص الرسالة',
    customer_number: 'عدد العملاء',
    sending_number: 'عدد المرسل لهم',
    media_link: 'عرض الملف',
    add_support: 'اضافة تذكرة',
    support_ticket: 'الدعم الفني',
    industrial: 'الصناعية',
    update_task: 'تحديث مهمة',
    whats_type: 'نوع الرسالة',
    whats_url_multi: 'رابط - الصورة او المستند او الفديو',
    last_action_date: 'تاريخ اخر اجراء',
    yes_19: 'اضافة جهاز شاشة 19 انش',
    yes_24: 'اضافة جهاز شاشة 24 انش',
    no_devices: 'لا يوجد اجهزة',
    include_computers: 'اضافة اجهزة',
    copies: 'عدد التراخيص',
    license_url: 'رابط الترخيص',
    task_response: 'المسؤول',
    note_text: 'نص الملاحظة',
    note_type: 'نوع المالحظة',
    generate_license: 'اصدار ترخيص',
    view_crm: 'عميل محتمل',
    agent: 'المندوب',
    next_action_date: 'تاريخ الاجراء',
    next_action: 'الاجراء القادم',
    last_action: 'الاجراء السابق',
    last_update: 'اخر تحديث',
    job_order: 'أمر العمل',
    training: 'التدريب',
    task_type: 'نوع المهمة',
    // added_by: 'اضافة بواسطة',
    note_date: 'تاريخ الملاحظة',
    edit_calendar: 'تعديل الحجز',
    change_booking_date: 'تغيير الموعد',
    booking_procedure:'اجراءات الحجز',
    work_name: 'وصف الموعد',
    estimated_price: 'السعر التقريبي',
    choose_custoemr: "اختر العميل",
    calendar_table: 'جدول الحجوزات',
    cancel_card: 'الغاء البطاقة',
    ready_to_deliver: 'جاهز للتسليم',
    brand_name: 'الشركة المصنعة',
    add_new_status: 'اضافة حالة جديدة',
    carsnumber: 'عدد السيارات',
    work_type: 'نوع العمل',
    // item_name: 'اسم قطع الغيار',
    add_spare_part: 'اضافة قطع غيار',
    add_new_spare_part: 'اضافة قطع غيار',
    spare_part_item_code: 'رقم القطعة',
    ment_description: 'أعمال الصيانة',
    customer_status: 'حالة العميل',
    tasks_status: 'المهام',
    warantly_status: 'الضمان',
    calendar_status: 'الحجوزات',
    book_calendar: 'الحجوزات',
    start_time: 'توقيت البدء',
    deadline_time: 'توقيت الانتهاء',
    add_booking: 'اضافة حجز',
    start_time_required: 'توقيت البدء والانتهاء مطلوبين دائما',
    plate_number_required: 'يجب اختيار رقم اللوحة',
    add_warantly: 'اصلاح على الضمان',
    last_update_by: 'تحديث بواسطة',
    last_update_date: 'تاريخ اخر تحديث',
    closed_date: 'تاريخ الاغلاق',
    task_start_end_date_required: 'تاريخ البدء والانتهاء مطلوبين دائما',
    task_description_required: 'يجب اضافة وصف للمهمة',
    task_to: 'تحويل الى',
    dead_line: 'تاريخ الانتهاء',
    task_description: 'وصف المهمة',
    add_task: 'اضافة مهمة',
    tasks: 'المهام',
    financial_request: 'المطالبات المالية',
    report: 'تقرير',
    unpaid_job_orders: 'غير مفوتر',
    finance_report: 'الحركة المالية',
    add_notes: 'اضافة ملاحظة',
    cars: 'السيارات',
    add_fincance_request: 'مطالبة مالية',
    view_customer: 'استعراض عميل',
    name_info_required: "اسم العميل مطلوب ويجب ان يكون اكثر من خمس احرف",
    mobile_info_required: 'رقم الاتصال مطلوب ويجب ان يبدأ بـ 05',
    add_your_cash: 'ادخل المبلغ النقدي هنا بحسب الفئات',
    book_tables: 'حجز طاولة',
    menu: 'القائمة',
    smiller_products: 'الاصناف المتشابهة',
    cashair: 'الكاشير',
    managment: 'الادارة',
    invoice_summary: 'ملخص الفاتورة',
    add_client_voucher: 'سداد عميل',
    total_payment_to_Client: 'المبلغ المستحق الدفع من العميل: ',
    client_appointment: 'حجز موعد',
    client_statement_pos: 'حساب عميل',
    total_payment_to_vendor: 'اجمالي الدفعات المتسحقة للمورد: ',
    clode_shift: 'اغلاق يومية',
    add_purchase: 'اضافة مشتريات',
    calendar: 'المواعيد',
    open_new_shift: 'فتح الوردية',
    opening_balance: 'الرصيد الافتتاحي',
    _total_1_ryals: '1 SAR',
    _total_5_ryals: '5 SAR',
    _total_10_ryals: '10 SAR',
    _total_20_ryals: '20 SAR',
    _total_50_ryals: '50 SAR',
    _total_100_ryals: '100 SAR',
    _total_500_ryals: '500 SAR',
    open_shift: 'فتح الوردية',
    remainpso: 'المتوفر',
    current_qty: 'الكمية المتوفرة',
    total_income: 'اجمالي المبيعات',
    sales_qtty: 'الكمية المباعة',
    max_items_sales: 'الأكثر مبيعا',
    less_items_sates: 'الاصناف الراكدة',
    zero_items: 'الاصناف المنتهية',
    daily_report: 'تقرير اليومية',
    search_by_name: 'البحث بالاسم',
    today_date: 'تاريخ اليوم',
    fix_it: 'تثبيت',
    monthly_deduct: 'الخصم الشهري',
    deduct_amount: 'مبلغ الخصم',
    monthly_late: 'التأخير الشهري',
    deduct_type: 'نوع الخصم',
    minute_late: 'التاخير بالدقائق',
    deduct_resone: 'سبب الخصم',
    returned: 'المرتجع',
    part_amount: 'قيمة القسط',
    number_of_parts: 'عدد الاقساط',
    paid_in_advanced_total: 'اجمالي السلف',
    deduct: 'الخصومات',
    service: 'خدمات',
    mentanace_card: 'أوامر التشغيل',
    custdy: 'العهد',
    salary_total: 'اجمالي الراتب',
    lated_salaries: 'رواتب متأخرة',
    remain_salary: 'صافي الراتب',
    invoice_percentage: 'نسبة المبيعات',
    card_percentage: 'نسبة كروت الصيانة',
    posted_salary: 'الراتب المصدر',
    indemnity: 'مكافأة نهاية الخدمة',
    post_salary: 'اصدار راتب',
    add_reward: 'اضافة مكافأة',
    pay_advanced: 'اضافة سلفة',
    deduct_from_salary: 'خصم من الراتب',
    emp_statment: 'كشف حساب ',
    pay_from_cash: 'الدفع من الصندوق',
    pay_from_custdy: 'الدفع من العهدة',
    terms: 'الشروط والأحكام',
    vendor_payments: 'دفعات موردين',
    work_hours: 'ساعات العمل',
    terms_and_conditions: 'الشروط والاحكام',
    back: 'رجوع',
    customer_sign: 'توقيع العميل',
    width: 'عرض',
    thickness: 'سماكة',
    limited: 'محدود',
    custody_statement: 'كشف عهد موظف',
    add_custody: 'اضافة عهدة',
    remain_custody: 'المتبقي من العهدة',
    return_custody: 'المدفوع من العهدة',
    custody: 'عهدة الموظف',
    full_cancel: 'الغاء كامل',
    partial_cancel: 'الغاء جزئي',
    full_cancel_reson: 'سبب الغاء كامل الطلب',
    cancel_reson: 'سبب الالغاء',
    cancel_qtty: 'الكمية الملغاه',
    order_items: 'اصناف الطلب',
    order_details: 'تفاصيل الطلب',
    order_time: 'وقت الطلب',
    order_date: 'تاريخ الطلب',
    orderid: 'رقم الطلب',
    view_order: 'عرض الطلب',
    add_order: 'اضافة طلب',
    cancel_order: 'الغاء الطلب',
    download: 'تحميل',
    file_name: 'اسم الملف',
    file_size: 'حجم الملف',
    client_statment: 'كشف حساب',
    salary_paid: 'سند صرف راتب',
    add_vendor_voucher: 'تسديد مورد',
    period_balance: 'رصيد المدة',
    credit_note: 'اشعار دائن',
    purchase_and_expenses: 'مشتريات ومصروفات',
    total_balance: "اجمالي الرصيد",
    previews_balance: "الرصيد السابق",
    today_balance: 'رصيد اليوم',
    daily_summary_for_date:'الملخص اليومي لتاريخ ',
    time: 'الوقت',
    payment_type: 'طريقة الدفع',
    amount: 'المبلغ',
    debet: 'مدين',
    details: 'التفاصيل',
    agree: 'موافق',
    row_type: 'نوع الاضافة',
    add_new_work_expenses: 'اضافة اصالاحات اخرى او قطع غيار',
    complete: 'انهاء',
    vin_numebr: 'رقم الهيكل',
    isbilled: 'الفوترة',
    approve_card: 'أمر التشغيل',
    chose_car_before_continue: 'يجب اختيار سيارة حتى تتم الاضافة',
    plase_select_car_scratch: 'يجب عليك ان ترسم على صورة السيارة اماكن الصدمات او الخدوش',
    you_must_add_atleast_one_work_item: 'يجب عليك اضافة وصف واحد على الاقل - او تأكد من انك ضغطت على زر اضافة بجانب الوصف بعد تعبئة البيانات والاسعار',
    you_must_add_one_car_atleast: 'يجب عليك اضافة سيارة على الاقل للمتابعة',
    car_information_must_be_complated: 'معلومات السيارة الموديل ونوع السيارة للوحة رقم  {{plate_number}} مطلوب للمتابعة',
    company_name_required: 'اسم الشركة مطلوب للمتابعة',
    customer_name_required: 'اسم العميل مطلوب للمتابعة',
    company_address_required: 'عنوان الشركة مطلوب للمتابعة',
    entity_name_required: 'اسم الجهة الحكومية مطلوب للمتابعة',
    chose_customer: 'اختار العميل',
    chose_company: 'اختر الشركة',
    chose_entity: 'اختر الجهة',
    reset: 'مسح',
    undo: 'تراجع',
    cars_status: 'حالة السيارات عند الاستلام',
    add_new_card: 'اضافة امر تشغيل',
    add_invocie: 'اضافة فاتورة',
    are_you_sure: 'هل أنت متاكد بانك تريد المتابعة',
    changeNote: 'اضافة الملاحظات',
    out_payments: 'سندات الصرف',
    in_payments: 'سندات القبض',
    cars_list: 'قائمة السيارات',
    total_discount: 'مبلغ الخصم',
    total_b_d: 'الاجمالي قبل الخصم',
    chose_car: 'اختار السيارة',
    expenses_invoices: 'فواتير المصروفات',
    bid_number: 'رقم المنافسة',
    view_quot_pdf: 'تحميل العرض',
    footer_extra: 'اضافات للفوتر',
    add_car: 'اضافة سيارة',
    entity_name: 'اسم الجهة',
    branch_name: 'اسم الفرع',
    request_number: 'رقم الطلب',
    order_number: 'رقم الأمر',
    po_date: 'تاريخ المطالبة',
    new_customer: 'عميل جديد',
    add_invoice_notes: 'اضافة ملاحظات على الفاتورة (ضمان - اشتراطات - غيرها)',
    whatsapp: 'واتس اب',
    for_more_information_or_support_firstline: 'للدعم الفني الاتصال',
    for_more_information_or_support_secondline: ' 0595907777 ',
    customer_company_info_required: 'عنوان واسم الشركة مطلوب',
    view_expenses: 'عرض فاتورة المصروفات',
    view_quotation: 'عرض السعر',
    expenses_type_reoprt: 'تقرير أنواع المصروفات',
    print_out_voucher: 'طباعة سند صرف',
    day: 'اليوم',
    this_item_service_item: 'لا يمكن اضافة الصنف الخدمي إلى فاتورة المشتريات',
    inventory_item: 'صنف مخزون',
    service_item: 'صنف خدمي',
    product_type: 'نوع الصنف',
    product_category: 'القسم',
    price_without_vat: 'السعر بدون ضريبة',
    price_inc_vat: 'السعر شامل الضريبة',
    default_cost: 'التكلفة الافتراضية',
    added_value_tax: 'تفعيل ضريبة القيمة المضافة',
    current_qtty: 'الكمية المتوفرة',
    add_edit_product: 'اضافة / تعديل منتج',
    // fftotal: 'الاجملاي',
    vat_summary: 'ملخص التقرير الضريبي',
    return_invoices: 'مرتجع الفواتير',
    view_page: 'معاينة الصفحة',
    government_clinet: ' حكومي',
    add_attachement: 'اضافة مرفقات',
    car_status_receive: 'حالة استلام السيارة',
    printing_header: 'هيدر الطباعة',
    invoice_body: 'صفحة الفاتورة',
    voucher_body: 'صفحة السند',
    card_body: 'صفحة كرت الصيانة',
    printing_footer: 'فوتر الطباعة',
    newpassword: 'كلمة المرور الجديدة',
    oldpassword: 'كلمة المرور القديمة',
    changePassword: 'تغيير كلمة المرور',
    fftotal: 'الاجمالي',
    total_without_vat: 'المجموع بدون ضريبة',
    chose_saler_name: 'اختر اسم البائع',
    chose_payment_method: 'اختر طريقة الدفع',
    chose_vat_type: 'اختر نوع الضريبة',
    chose_invoice_type: 'اختر نوع الفاتورة',
    chose_customer_type: 'اختر نوع العميل',
    print_in_voucher: 'طباعة سند قبض',
    save_n_print: 'حفظ وطباعة',
    car_ifnormation_required: 'بيانات السيارة مطلوبة رقم اللوحة، نوع السيارة و سنة الصنع',
    add_direct_invoice: 'اضافة فاتورة مباشرة',
    name_ar_en_required: 'نوع المصروف عربي انجليزي مطلوب دائما',
    add_expense_type: 'اضافة نوع مصروفات',
    invoice_export: 'اصدار فاتورة',
    sales_vat: 'ضريبة المبيعات',
    bill_vat: 'ضريبة المشتريات',
    print_voucher: 'طباعة ',
    purchase_summary: 'ملخص المشتريات',
    purchase_bill: 'فواتير المشتريات ',
    old_balance: 'رصيد سابق',
    expnese: 'المصروفات',
    other: 'اخرى',
    income_expense_report: 'تقرير الدخل اليومي',
    expenses_type_added: 'تم اضافة نوع المصروف بنجاح',
    add_new_expense_type: 'اضافة نوع مصروفات جديد',
    expenses_type_updated: 'تم تعديل نوع المصروف بنجاح',
    expense_namear: 'نوع المصروف عربي',
    expense_nameen: 'نوع المصروف انجليزي', 
    tafweed: 'التفويض',
    terms_of_use: 'شروط الاستخدام',
    logo_img: 'صورة الشعار',
    year: 'السنة',
    chose_month: ' الشهر',
    convert_to_invoice: 'تحويل لفاتورة',
    cards_expsenses: 'مصاريف الكروت',
    cards_number: 'عدد الكروت',
    catd_totals: 'اجمالي الكروت',
    totalPercent: 'النسبة المستحقة',
    percent_report: 'تقرير النسب',
    salary_report: 'تقرير الرواتب',
    sendWhats: '<i class="fa fa-whatsapp"></i>',
    total_invoices: 'اجمالي الفواتير',
    total_remain: 'اجمالي المتبقي',
    vin_number: 'رقم الهيكل',
    start_date: 'تاريخ البدء',
    end_date: 'تاريخ الانتهاء',
    worker_name: 'اسم الفني',
    work_price: 'وصف العمل',
    you_cannot_delete_this_item_paid_more_than_total: 'لا يمكنك حذف هذا العنصر وذلك لأن قيمة الدفعات اكبر من قيمة الكرت',
    company_name_en: 'Company English Name',
    contact_number: 'رقم الاتصال',
    booktime: 'وقت الطلب',
    orders: 'الطلبات',
    add_product: 'اضافة منتج',
    welcome: "مرحبا ",
    username: 'اسم المستخدم',
    item_cost: 'التكلفة',
    sent_requests: 'الارساليات',
    print_page: 'طباعة الصفحة',
    print_pagexport_pdfe: 'تصدير PDF',
    ment_req_cards_new: 'طلبات الصيانة الجديدة',
    ment_req_cards_late: 'طلبات الصيانة المتأخرة',
    ment_req_cards_closed: 'طلبات الصيانة المغلقة',
    payment_requests:'الدفعات',
    requests:'الارساليات',
    home: 'الرئيسية',
    additional_method: 'خصم اضافي غير خاضع للضريبة',
    adding_date: 'تاريخ الاضافة',
    ammount: 'القيمة',
    method: 'العملية',
    add_project: 'اضافة مشروع',
    projects: 'المبيعات',
    expeneses_type: 'نوع المصروفات',
    account_name: 'اسم الحساب',
    integrated_with_expenses: 'ربط المصروفات',
    account_integrated: 'الربط مع',
    default_type: 'قيمة الحساب',
    account_type: 'نوع الحساب',
    acount_name: 'اسم الحساب',
    update_acount: 'تحديث الحساب',
    accoutn_option: 'خيارات الحساب',
    mainaccount: 'الحساب الرئيسي',
    account_name_ar: 'الاسم العربي',
    account_name_en: 'الاسم الانجليزي',
    add_new_account: 'حساب جديد',
    view_employee: 'استعراض موظف',
    customer_addedd: 'تمت اضافة العميل بنجاح',
    please_choose_worker: 'يرجى اختيار منفذ العمل',
    job_price_required: 'يرجى اضافة سعر شغل اليد حتى تستطيع المتابعة',
    car_info_required: 'معلومات السيارة مطلوبة',
    delivery_date_required: 'تاريخ التسليم مطلوب',
    customer_info_required: 'رقم الاتصال يجب ان يبدأ بـ 05 واسم العميل مطلوب دائما',
    maintenance_cards: 'اوامر التشغيل',
    sales_crm: 'المبيعات',
    exp_management: 'مصروفات ادارية',
    bnl_report: 'قائمة الدخل',
    gozi_report: 'تقرير التأمينات',
    balance_report: 'الميزانية',
    addJV: 'اضافة قيد',
    notes: 'ملاحظات',
    jvnom: 'رقم القيد',
    vendor_name_required: 'اسم المورد مطلوب ويجب ان يكون اكبر من خمس احرف',
    finance_jvs: 'القيود',
    vendor_mobile_required: 'رقم اتصال المورد مطلوب ويجب ان يكون اكثر من خمس ارقام',
    vendor_updated_successufly: 'تمت اضافة / تعديل المورد بنجاح',
    update_vendor: 'تعديل مورد',
    add_vendor: 'اضافة مورد',
    invoice_total: 'اجمالي الفواتير',
    vat_total: 'قيمة الضريبة',
    vendors: 'الموردين',
    price: 'السعر',
    late: 'متأخر',
    fixed_assets: 'الاصول الثابتة',
    class_name: 'اسم الحساب',
    add_pay_type: 'إضافة طريقة دفع',
    statment: 'كشف حساب',
    namear: 'الاسم العربي',
    nameen: 'الاسم الانجليزي',
    classid: 'رقم الحساب',
    amounttotal: 'اجمالي المبلغ',
    car_report: 'تقرير المركبة',
    definition: 'ربط الشجرة ',
    itotals_per_paymethod: 'اجمالي الدخل بحسب طريقة الدفع',
    ototals_per_paymethod: 'اجمالي المصروفات بحسب طريقة الدفع',
    account_statement: ' كشف حساب',
    account_balance_report: 'تقرير الموازنة',
    openingbalance: 'الرصيد الافتتاحي',
    transaction: 'رصيد العمليات',
    chart_of_account: 'شجرة الحسابات',
    paytypes: 'طرق الدفع',
    balance: 'الرصيد',
    accounts: 'الحسابات',
    incomes: 'الدخل',
    amountprice: 'المبلغ',
    account: 'الحساب',
    vlaue: 'القيمة',
    langname: 'ar',
    dir: 'rtl',
    ldir: 'ltr',
    algin: 'right',
    lalgin: 'left',
    invoiceno: 'رقم الفاتورة',
    plate_number: 'رقم اللوحة',
    main_menu: 'القائمة',
    search: 'بحث',
    invoices: 'الفواتير',
    add_invoice: 'اضافة فاتورة',
    invtype: 'نوع الفاتورة',
    customer: 'العميل',
    mobile: 'رقم الاتصال',
    plate: 'اللوحة',
    model: 'الموديل',
    inv_date: 'التاريخ',
    total: 'المجموع',
    vat: 'الضريبة',
    ftotal: 'الاجمالي مع الضريبة',
    paid: 'المدفوع',
    remain: 'المتبقي',
    remaremain_amountin: 'المبلغ المتبقي على العميل',
    action: 'الاجراء',
    status: 'الحالة',
    view: 'عرض',
    totals: 'المجموع',
    close: 'اغلاق',
    customer_name: 'اسم العميل',
    customer_type: 'نوع العميل',
    company_name: 'اسم الشركة / الجهة',
    comapny_address: 'عنوان الشركة',
    company_vatid: 'الرقم الضريبي',
    received_amount: 'المبلغ المدفوع',
    car_model: 'نوع السيارة',
    car_year: 'سنة الصنع',
    car_color: 'اللون',
    invoice_type: 'نوع الفاتورة',
    vat_type: 'نوع الضريبة',
    payment_method: 'طريقة الدفع',
    saler_name: 'البائع',
    invoice_date: 'تاريخ الفاتورة',
    invoice_details: 'تفاصيل الفاتورة',
    item_code: 'رمز الصنف',
    description: 'الوصف',
    qtty: 'العدد',
    item_price: 'السعر',
    discount: 'الخصم',
    create_invoice: 'إنشاء فاتورة',
    cash_payment: 'الدفع نقدا',
    bank_payment: 'تحويل بنكي',
    span_payment: 'الدفعة بواسطة الشبكة',
    add: 'إضافة',
    individual_client: ' فردي',
    company_clinet: ' شركة',
    prices_include_vat: 'السعر شامل الضريبة',
    prices_exclusive_vat: 'السعر غير شامل الضريبة',
    prices_without_vat: 'بدون ضريبة',
    none_paid_invoice: 'فاتورة آجلة',
    paid_invoice: 'فاتورة نقدية', 
    multi_payments: 'الدفع المتعدد',
    required_field: 'الحقل مطلوب',
    view_purchase_bill: 'عرض فاتورة مشتريات',
    you_can_not_add_empty_invoice: 'لا يمكن اضافة فاتورة فارغة',
    company_ifnormation_required: 'يرجى ادخال بيانات الشركة اسم الشركة، رقم السجل، العنوان',
    invoice_added: 'تمت اضافة الفاتورة بنجاح',
    can_not_add_item_withoud_discription: 'لا يمكن اضافة صنف بدون وصف',
    item_qtty_must_be_more_than_zero: 'لا يمكن اضافة صنف بدون كمية',
    item_price_must_be_more_than_zero: 'لا يمكن اضافة صنف بدون سعر',
    print_invoice: 'طباعة الفاتورة',
    view_pdf: 'تحميل الفاتورة',
    invoice_return: 'ارجاع الفاتورة',
    make_payment: 'سداد دفعة',
    invoice_tme: 'وقت الفاتورة',
    return_date: 'تاريخ الارجاع',  
    return_time: 'وقت الارجاع',  
    return_case: 'سبب الارجاع',
    total_exclusive_vat: 'اجمالي بدون ضريبة',
    total_include_vat: 'اجمالي مع ضريبة',
    payments: 'الدفعات',
    payment_date: 'تاريخ الدفعة',
    payment_time: 'توقيت الدفعة',
    payment_amount: 'قيمة الدفعة ',
    payment_cash: 'نقدا',
    payment_span: 'شبكة',
    payment_bank: 'تحويل',
    return_invoice: 'ارجاع فاتورة',
    return_amount: 'المبلغ المرتجع',
    return: 'ارجاع',
    maint_return: 'اعادة صيانة',
    system_modules: 'اقسام النظام',
    customers: 'العملاء',
    quotations: 'عروض السعر',
    accounting: 'المحاسبة',
    envintory: 'المخزون',
    reports: 'التقارير',
    settings: 'الاعدادات',
    logout: 'تسجيل الخروج',
    invoice_notes: 'ملاحظات الفاتورة',
    add_customer: 'اضافة عميل',
    additional_mobile: 'الرقم الاضافي',
    client_statement: 'كشف حساب',
    mobil_10_digits: 'رقم الجوال يجب ان يكون عشرة ارقام',
    customer_added: 'تمت اضافة العميل بنجاح',
    add_quotation: 'اضافة عرض ',
    quotnumber: 'رقم العرض',
    salesman: 'البائع',
    quot_date: 'تاريخ العرض',
    quotation_details: 'تفاصيل العرض',
    delete: 'حذف',
    company_client: 'عميل شركة',
    can_not_add_empty_row: 'لا يمكن اضافة صنف فارغ',
    please_add_company_info: 'يرجى اضافة بيانات الشركة',
    quotation_added: 'تمت اضافة العرض بنجاح',
    can_not_add_row_without_discription: 'لا يمكن اضافة صنف بدون وصف',
    can_not_add_row_with_qtty: 'جيب ان تكون الكمية اكبر من 0',
    date: 'التاريخ',
    credit: 'دائن',
    depit: 'مدين',
    new_quotations: 'العروض الجديدة',
    invoiced_quots: 'العروض المفوترة',
    canceled_quots: 'العروض الملغية',
    quotation_no: 'رقم العرض',
    printQuot: 'طباعة العرض',
    cancel_quto: 'الغاء العرض',
    quot_time: 'توقيت العرض',
    print: 'طباعة',
    cash_total: 'المجموع النقدي',
    span_total: 'بطاقات البنك',
    voutcher_no: 'رقم السند',
    receive_from: 'استلام من',
    receive_by: 'الاستلام بواسطة',
    cash: 'نقدا',
    span: 'شبكة',
    received_voucher: 'سندات القبض',
    exchange_voucher: 'سندات الصرف',
    employees: 'الموظفين',
    recurring_expensess: 'مصاريف دورية',
    add_voutchre: 'اضافة سند',
    voutcher_resone: 'سبب السند',
    emp_name: 'اسم الموظف',
    id_number: 'رقم الهوية',
    basic_salary: 'الراتب الاساسي',
    home_allownance: 'بدل السكن',
    trans_allownance: 'بدل النقل',
    food_allown: 'بدل الطعام',
    other_allown: 'بدلات اخرى',
    goze_perc: 'التأمينات',
    percentate: 'النسبة',
    update: 'تعديل',
    add_employee: 'اضافة موظف',
    add_expenses: 'اضافة مصروفات',
    expense_type: 'نوع المصروف',
    yearly: 'سنوي',
    midterm: 'نصف سنوي',
    quarterly: 'ربع سنوي',
    monthly: 'شهري',
    daily: 'يومي',
    expense_total: 'اجمالي المصروف',
    expense_period: 'مدة المصروف',
    calculation_will_be_done_automatic: 'Calculation values will be done automaticlly',
    office_rent: 'اجار المكتب',
    add_maint_request: 'اضافة امر تشغيل ',
    cardid: 'رقم البطاقة',
    received: 'الاستلام',
    delivered: 'التسليم',
    spare_partes: 'قطع الغيار',
    spare_parts: 'قطع الغيار',
    main_info: 'معلومات اساسية',
    received_date: 'تاريخ الاستلام',
    delivery_date: 'تاريخ التسليم',
    customer_info: 'معلومات العميل',
    company_info: 'معلومات الشركة',
    car_info: 'معلومات السيارة',
    received_status: 'حالة استلام السيارة كتابيا',
    work_description: 'وصف العمل',
    worker: "العمالة",
    advanced_payment: 'دفعة مقدمة',
    attachement: 'المرفقات',
    choose_file: 'اختر الملف',
    save: 'حفظ',
    additional_information: 'بيانات اضافية',
    work_description_required: 'وصف العمل مطلوب',
    added_sucess: 'تمت الاضافة بنجاح',
    add_user: 'إضافة مستخدم',
    update_user: 'تعديل مستخدم',
    users: 'المستخدمين',
    user_name: 'اسم الموظف',
    login_name: 'اسم الدخول',
    permission: 'الصلاحية',
    last_login: 'آخر دخول',
    save_login_info: 'حفظ بيانات الدخول',
    password: 'كلمة المرور',
    export_excel: 'تصدير اكسل',
    add_purcahse: 'اضافة مشتريات',
    vendor: 'المورد',
    purchase: 'المشتريات',
    inventory: 'المخزون',
    item_name: 'اسم الصنف',
    sales_invoices: 'فواتير المبيعات',
    sales: 'المبيعات',
    vat_required_return: 'قيمة الضريبة المضافة المستحقة (المستردة)',
    vat_report: 'تقرير الضريبة',
    dealy_report: 'تقرير اليومية',
    card_report: 'تقرير البطاقات',
    sales_report: 'تقرير المبيعات',
    return_report: 'تقرير المرتجع',
    percentage_report: 'تقرير النسب',
    percentage: 'النسبة',
    income: 'الدخل',
    expenses: 'المصروفات',
    expense: 'المصروف',
    period_expenses: 'المصاريف الدورية',
    closed_by: 'اغلق بواسطة',
    return_by: 'مرتجع عن طريق',
    shortcust_name: 'الاسم المختصر',
    vat_number: 'الرقم الضريبي',
    system_settings: 'اعدادات النظام',
    welcometo: 'مرحبا ',
    added_date: 'تاريخ الاضافة',
    added_time: 'التوقيت',
    photo_gallary: 'معرض الصور',
    print_card: 'طباعة البطاقة',
    car_description: 'وصف السيارة',
    invoice_samary: 'ملخص البطاقة',
    others: 'أخرى',
    view_card: 'استعراض البطاقة',
    card_closed: 'تم اغلاق البطاقة',
    please_check_data: 'يرجى التحقق من البيانات',
    cant_close_card_when_it_not_fully_paid: 'يجب استكمال الدفعات قبل اغلاق البطاقة',
    card_saved: 'تم حفظ البطاقة',
    view_invoice  : 'استعراض الفاتورة',
    customerid: 'المعرف',
    purchase_invoice: 'فاتورة مشتريات',
    billno: 'رقم الفاتورة',
    vendor_name: 'اسم المورد',
    vendor_mobile: 'رقم المورد',
    vendor_vatids: 'الرقم الضريبي للمورد',
    vendor_address: 'عنوان المورد',
    type: 'النوع',
    bill_type: 'نوع الفاتورة',
    bill_detates: 'تفاصيل الفاتورة',
    item_unit: 'الوحدة',
    pace: 'حبه',
    close_card: 'اغلاق البطاقة',
    chose_file: 'اختر الملف',
    upload: 'تحميل',
    full_name: 'الاسم كاملا',
    total_amount: 'اجمالي المبلغ',
    buy_goods: 'شراء بضاعة',
    sallary: 'راتب',
    buy_devices: 'شراء اجهزة',
    pay_for_bills: 'دفع فواتير',
    added_successfuly: 'تمت الاضافة بنجاح',
    nationality: 'الجنسية',
    percent: 'النسبة',
    group_name: 'اسم المجموعة',
    permissions: 'الصلاحيات',
    administratior: 'الادارة العامة',
    full_permissions: 'صلاحيات كاملة',
    accountant: 'المحاسبة',
    accountatn_permissions: 'كل الصلاحيات بدون الاعدادات',
    cashier: 'الكاشير',
    cashier_permissions: 'الفواتير والبطاقات',
    choose_employee: 'اختر الموظف',
    employees_and_users: 'الموظفين',
    salary_table: 'مسير الرواتب',
    projectname: 'اسم المشروع',
    inprogress: 'تحت العمل',
    new: 'جديد',
    closed: 'مغلق',
    not_invoiced: 'غير مفوتر',
    added_by: 'بواسطة',
    vendorid: 'معرف المورد',
    paid_in_advanced: 'السلف',
    paid_in_advanced_amount: 'قيمة السلفة',
    installment_number: 'عدد الدفعات',
    installment_amount: 'قيمةالدفعة',
    mor_than_zero: 'يرجى اضافة رقم اكبر من الصفر',
    emp_reward: 'المكافآت',
    emp_deducts: 'الخصومات',
    reward_amount: 'قيمة المكافأة',
    reward_resone: 'سبب المكافأة',
    deducts_amount: 'قيمة الخصم',
    deducts_resone: 'سبب الخصم',
    canceled: 'ملغي ',
    notpaid: 'لم يتم السداد',
    emp_custody: 'العهد',
    custody_amount: 'مبلغ العهدة',
    workstartdate: 'تاريخ بدء العمل',
    fix_salary: 'اصدار الراتب',
    card_expenses: 'مصروفات الصيانة من المخزون',
    invoice_expenses: 'مصاريف الفاتورة',
    expenses_summary: 'ملخص المصاريف',
    general_expenses: 'مصاريف عامة',
    cards_expenses: 'الصيانة',
    invoices_expenses: 'الفواتير',
    salaries: 'الرواتب',
    add_general_expense: ' مصروفات ',
    periodically: 'دورية',
    payit: 'سداد',
    pay_expenses: 'تسديد المصاريف',
    payment_period: 'مدة السداد',
    expense_amount: 'المبلغ',
    othermethod: "طرق دفع ", 
    invoices_total: 'اجمالي الفواتير', 
    invoices_remain: 'متبقي الفواتير', 
    other_expenses: 'مصاريف اخرى', 
    exp_total: 'اجمالي المصروفات', 
    exp_vat:'ضريبة المصروفات',
    building_no: 'رقم المبنى',
    street_name: 'اسم الشارع',
    branch_number: 'الرقم الفرعي',
    district: 'الحي',
    zipcode: 'الرمز البريدي',
    city:'المدينة',
    address: 'العنوان',
    update_customer: 'تعديل عميل',
    customer_updated: 'تم تعديل العميل',
    update_all: 'تعديل الكل',
    update_all_notes: 'تعديل الكل : تعديل كل الفواتير او سندات القبض بالبيانات الجديدة',
    curency: 'ريال',
    pos: 'نقطة بيع',
    cancel: 'الغاء',
    total_after_discount: 'اجمالي بعد الخصم',
    total_after_discountt: 'السعر بعد الخصم',
    multi_payment: 'الدفع المتعدد',
    paid_amount: 'المبلغ المدفوع',
    product_categories: 'أقسام المخزون',
    category_name: 'القسم',
    category_name_ar: 'اسم القسم عربي',
    category_name_en: 'اسم القسم انجليزي',
    category_type: 'نوع القسم',
    category_parent: 'القسم الرئيسي',
    subcat_count: 'الاقسام الفرعية',
    products: 'المنتجات', 
    add_category: 'اضافة قسم',
    main_cat: 'قسم رئيسي',
    sub_cat: 'قسم فرعي',
    income_report: 'تقرير الدخل',
    engine_meter: 'عداد السيارة (كم)',
    next_oil_change: 'تغيير الزيت بعد (كم)',
    crt_number: 'السجل التجاري',
    cashflow: 'حركة النقد',
    process_job_list: 'اوامر تشغيل تحت الاجراء',
    closed_job_list: "أوامر تشغيل مغلقة",
    not_invoice_job_list: "أوامر تشغيل غير مفوتر",
    invoiced_job_list: "أوامر تشغيل مفوتر",
    current_paid_job_list: "أوامر تشغيل قيد التحصيل",
    choose_mentainance_date: 'اختر تاريخ الصيانة',
    mentainance_time: 'وقت الصيانة',
    offers_programs: 'البرامج التسويقية',
    program_name: 'اسم البرنامج',
    booking_date: 'تاريخ الموعد',
    booking_time: 'وقت الموعد',
    contract_details: 'تفاصيل العقد',
    print_job_order: 'طباعة امر العمل',
    work_total_price: "صافي شغل اليد",
    other_profit_total: 'صافي الارباح الاخرى',
    all_: 'الكل',
    chose_store: 'اختر المستودع',
    cost_center_name_ar: 'مركز التكلفة - عربي',
    cost_center_name_en: 'مركز التكلفة - انجليزي',
    costCenter: 'مركز التكلفة',
    addCostCenter: 'اضافة مركز تكلفة',
    without_cost_center: 'بدون مركز تكلفة',
    account_client_type: 'نوع حساب العميل',
    customer_client: 'حساب عميل',
    customer_group_client: 'حساب قسم رئيسي',
    main_clients: 'الحساب الرئيسي',
    without_account: 'بدون حساب رئيسي',
    depreciation_account: 'حساب الاهلاك',
    quarter: 'ربع سنوي',
    semiannually: 'نصف سنوي',
    annually: 'سنوي',
    delivery_note: 'سند استلام',
    consumption_rate: 'الخصم بـ',
    consumption_fees: 'رسوم استهلاك',
    consumption_value: 'رسوم استهلاك',
  }

  export default arLang;